// Colors
//
// Settings for the color
// サイトの背景カラー
$site_bg_color: #203352;
// キーカラー
$key_color_1: #0E4496;
$key_color_2: #0B4A6D;
// 背景カラー
$bg_color_2: #EAE0C6;
// フォントカラー
$main_color: #fff;
$main_color_2: #333;
// リンクカラー
$link_color: #0E4496;
$link_color_hover: #F00;
$border_color: #666;
// ナビカラー
$gnav_color: #fff;
$gnav_color_bg_pc: rgba(255, 255, 255, 0.9); //RGBで色指定
$gnav_color_bg_sp: #0B4A6D; //RGBで色指定
$gnav_color_txt: #333;


//gnav PC リスト 横マージン
$gnav_margin: .6rem;


// SNS
// アイコン数
$sns_quantity: 2;
// pc 横幅と間隔
$sns_width: 36px;
$sns_margin: 26px;
// sp 横幅と間隔
$sns_width_sp: 36px;
$sns_margin_sp: 14px;


// font-family
//
// Settings for the font-family
$base_font: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", serif;
$base_font_2: "ＭＳ Ｐ明朝", "MS PMincho","ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "serif";
$nav_font: 'Noto Sans JP', sans-serif;
$tit_font: 'Mansalva', cursive;
// width,height
//
// Settings for the width
$inner_width: 954px;

//mixin
//
//Settings for the flex
@mixin flex() {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

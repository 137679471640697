@import 'variable';
/* ------------------------------------------------------------
*
common parts
*
------------------------------------------------------------ */

%html {
    height: 100%;
    font-family: $base_font;
}

html {
    @extend %html;
}

body {
    @extend %html;
    background-color: $site_bg_color;
    color: $main_color_2;
}

*,
::after,
::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

#wrapper {
    /*display: none;*/
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

a {
	color: $link_color;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;

    &:hover {
		color: $link_color_hover;
        
        img, i {
            opacity: .6;
        }
        
    }
}

.flwrap {
    @include flex();
    flex-wrap: wrap;
}

.flcenter {
    @include flex();
    flex-wrap: wrap;
    justify-content: center;
}

.scap {
    font-size: .6rem;
}

.cap {
    font-size: .94rem;
}

.caution {
    font-size: .9em;
}

.vbtm {
    vertical-align: bottom;
}

.vmid {
    vertical-align: middle;
}

.nobd {
    border: none !important;
}

ul {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.inner {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: $inner_width;
    box-sizing: border-box;
}

br {
    &.sp {
        display: none;
    }
	&.pc {
        display: block;
    }
}

.switch_pc {
    display: block;
}

.switch_sp {
    display: none;
}

img {
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: middle;
}

.center {
    text-align: center;
}

.btn {
    margin: 0 auto;
    width: 260px;

    a {
        display: block;
        padding: .55rem 0;
        border-radius: 5px;
        background: $key_color_1;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        line-height: 1.6;
        
        &:hover {
            opacity: .7;
        }
    }
}

.btn-outline {
    display: inline-block;

    a {
       
        padding: .49rem 2.2rem;
        @extend %btn_outline;
        border-radius: 10px; 
        font-size: .9rem;

        &:hover {
            @extend %btn_outline_hov;
            text-decoration: none;
        }
        
    }
}

.btn-cat {
    a {
        width: 135px;
        @extend %btn_outline;
        border-radius: 5px;
        font-size: .8rem;

        &:hover {
            @extend %btn_outline_hov;
        }
        
    }
}

%btn_outline {
    display: block;
    border: 1px solid $key_color_1;
    background-color: transparent;
    color: $key_color_1;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.6;
}

%btn_outline_hov {
    opacity: .7;
    color: $key_color_1;
    text-decoration: none;
}

.btn_wrap {
    text-align: center;
}



/* ------------------------------------------------------------
*
header
*
------------------------------------------------------------ */

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 2.5rem;
    background-color: $gnav_color_bg_pc;
    z-index: 2;
    -webkit-transition: top 0.3s;
    -moz-transition: top 0.3s;
    -o-transition: top 0.3s;
    transition: top 0.3s;

    &.top {
        top: -5.5rem;
    }

    .inner {
        padding: 0;
        // max-width: 1280px;
    }
}

#h_top {
    position: fixed;
    top: 0;
    padding: 0;
    width: 100%;
    min-height: 2.5rem;
    z-index: 4;

    .inner {
        padding: 0;
    }

    #gnav {
        ul {
            li {
                a {
                    color: $gnav_color;
                }
            }
        }
    }

    .logo {
        img {
            &.logo_sp {
                display: none;
            }
        }
    }
}

#gnav {
    padding: .3rem 0;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .menu {
        float: left;
        margin-bottom: 0;
        padding-left: 0;
        width: 100%;
    }

    .h_sns {
        float: right;

        li {
            margin-bottom: 0;
            line-height: 1;
            font-weight: normal;
            
            a {
                padding: 0 $gnav_margin;
                color: $key_color_1;
                font-size: 1.88rem;
            }
        }
    }

    li {
        float: left;
        margin-bottom: 0;
        font-size: 1rem;
        font-family: $nav_font;
        font-weight: 600;
        line-height: 1;

        a {
            display: block;
            padding: .4rem $gnav_margin;
            text-decoration: none;
            color: $gnav_color_txt;
        }
    }
}

/* ------------------------------------------------------------
*
common 
*
------------------------------------------------------------ */
.t_wrap {
    position: relative;
    padding-bottom: .5rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $border_color;
}

.title {
    position: relative;
    padding-top: 3rem;
    padding-left: 1.1rem;
    color: $main_color_2;
    text-align: left;
    font-size: 4.4rem;
    font-family: $tit_font;
    text-transform: uppercase;
    line-height: 1.12;
    opacity: 0.1;
    position: relative;
}

.title_jp {
    position: absolute;
    bottom: 10px;
    margin-bottom: 0;
    color: $main_color_2;
    text-align: left;
    font-family: 'Noto Serif JP', serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
}

.bg {
    background-color: $main_color;
}

.bg_2 {
    background-color: #203352;
}

.th {
    padding: 1.7rem 0 1rem;
    text-align: center;
    font-size: .6rem;
    font-weight: bold;
    color: $gnav_color;

    .actor {
        margin-bottom: .8rem;
        font-size: 1.31rem;
        font-family: 'Noto Serif JP', serif;
        line-height: 1.71;
    }

    .cast {
        margin-bottom: .6rem;
        font-size: .75rem;
        font-weight: normal;
        line-height: 1.33;
        opacity: .75;
    }

    .billing {
        font-weight: normal;
        line-height: 1.4;
        opacity: .5;
    }
}

.th_theatre {
    padding-bottom: 2.4rem;
    color: $gnav_color;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4;

    span {
        font-size: 1rem;
        line-height: 2.1;
    }
}

.news_list {
    margin-bottom: 2.1rem;
    padding-left: 0;

    li {
        @include flex;
        margin: 0 -.93rem;

        p {
            margin-bottom: 1rem;
            padding: 0 .93rem;
            font-size: 1rem;
            line-height: 1.65;
            align-self: center;
        }

        .tit {
            width: 65%;
            a {
                color: $main_color_2;
            }
        }
    }
}


/* ------------------------------------------------------------
*
footer 
*
------------------------------------------------------------ */

#footer {
    padding: .5rem 0 0;
    background: #181E4D url(/elements/img/ft_bg_pc.jpg) no-repeat;
    background-size: 100% auto;
    color: $main_color;
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-size: 1.1rem;
    // letter-spacing: 1px;

    #ft_sns {
        padding: 2rem 0 0;
    
        ul {
            margin-bottom: 0;
            padding-left: 0;
            text-align: center;
    
            li {
                display: inline-block;
                padding: 0 1.6rem;
                font-size: 2.25rem;
                line-height: 1;
    
                a {
                    color: $main_color;
                    &:hover {
                        opacity: .75;
                    }
                }
                
                i {
                    font-size: 2.25rem;
                }
            }
        }
        
    }

    .ft_logo {
        margin: 2.9rem 0 2.3rem;

        img {
            width: 18rem;
        }
    }
	
	.th {
        padding: 0 0 1.5rem;
		color: $main_color;
        line-height: 1.7;
        
        ul {
            margin-bottom: 0;
            padding-bottom: 2.3rem;
            padding-left: 0;
            
            .actors {
                padding-bottom: 1rem;
                font-size: 1.32rem;
                line-height: 1.7;
            }
    
            .support{
                padding-bottom: .7rem;
                font-size: .75rem;
                font-weight: normal;
                line-height: 1.4;
            }

            .staff {
                font-weight: normal;
                font-size: .6rem;
                line-height: 1.4;
            }
        }
    
        .theatre {
            margin-bottom: 0;
            padding-bottom: 0;
            color: $main_color;
            font-size: 1.5rem;
            line-height: 1.4;

            span {
                font-size: 1rem;
                line-height: 2.1;
            }
        }
    }
    
    .scap {
        color: $main_color_2;
        line-height: 1.8;
    }

    #copyright {
        padding: 1.4em 0 3.5rem;
        color: $main_color;
        font-size: .8rem;
        letter-spacing: initial;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    #header {
        .inner {
            width: 95%;
        }
    }
}

@media (max-width: 767px) {
    .switch_pc {
        display: none;
    }

    .switch_sp {
        display: block;
    }

    br {
        &.pc {
            display: none;
        }
        &.sp {
            display: block;
        }
    }

    #wrapper {
        min-width: inherit;
    }
    
    .inner {
        width: 100%;
    }

    /* ------------------------------------------------------------
    *
    header
    *
    ------------------------------------------------------------ */
    #h_top {
        display: none !important;
    }

    #header {
        background: none;

        &.top {
            top: 0;
        }

        .inner {
            width: 100%;
        }

        .h_gnav {
            height: 100vh;
        }

        .logo {
            padding: 1.25rem 1.25rem 2.8rem;
            img {
                width: 9rem;
            }
        }

        #gnav {
            position: absolute;
            padding: 0;
            width: 100%;
            height: 100vh;
            background-color: #0B4A6D;
            clear: both;
            z-index: 200;

            .menu {
                margin: 0 .95rem 2.2rem;
                padding-bottom: .8rem;
                width: auto;
                border-bottom: 1px solid $gnav_color;

                li {
                    float: left;
                    margin-bottom: 1.1rem;
                    width: 50%;
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: bold;
                    line-height: 1.8;
    
                    a {
                        display: block;
                        padding: 0;
                        color: $gnav_color;
                    }
                }
            }

            .h_sns {
                @include flex();
                float: none;
                width: 100%;
                justify-content: center;
                margin: 0 auto;

                li {
                    width: auto;

                    a {
                        margin: 0;
                        padding: 0 1.45rem;
                        color: $main_color;
                        font-size: 2.25rem;
                    }

                    &:last-child {
                        padding-bottom: 0;

                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .btn-toggle-menu {
        position: fixed;
        top: 15px;
        right: 15px;
        padding: .6rem 0;
        width: 55px;
        height: 55px;
        border-radius: 3px;
        background-color: rgba($color: $gnav_color, $alpha: .85);
        cursor: pointer;
        z-index: 201;
        outline: none;

        &.close {
            position: absolute;

            .icon-bar {
                position: relative;
                top: 1px;
                left: 4px;
                right: 15px;
            }
        }

        .icon-bar {
            display: block;
            margin: 0 .94rem;
            width: 26px;
            height: 4px;
            border-radius: 1.6px;
            background-color: $gnav_color_bg_sp;
            color: $gnav_color;
            transition: all .2s;
            -webkit-transition: all .2s;

            & + .icon-bar {
                margin-top: .3rem;
            }
        }

        &.collapsed {
            .top-bar {
                height: 6px;
                border-radius: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform-origin: 15% 140%;
                -webkit-transform-origin: 15% 140%;
                -moz-transform-origin: 15% 140%;
                -ms-transform-origin: 15% 140%;
            }

            .middle-bar {
                opacity: 0;
            }

            .bottom-bar {
                height: 6px;
                border-radius: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform-origin: 0% -70%;
                -webkit-transform-origin: 0% -70%;
                -moz-transform-origin: 0% -70%;
                -ms-transform-origin: 0% -70%;
            }

            span{
                &.toggle-txt {
                    margin-top: .1rem;
                }
            }
        }

        .top-bar {
            transform: rotate(0);
            -webkit-transform: rotate(0);
        }

        .middle-bar {
            opacity: 1;
        }

        .bottom-bar {
            transform: rotate(0);
            -webkit-transform: rotate(0);
        }

        &:focus {
            outline: none;
        }
    }

    span {
        &.toggle-txt {
            display: block;
            margin-top: .3rem;
            color: $key_color_1;
            text-align: center;
            font-family: $nav_font;
            font-size: .75rem;
            // font-weight: bold;
        }
    }

    .nav-menu-enable {
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height .7s;
        transition: max-height .7s;

        &.is-hide {
            .nav-menu-content {
                display: none;
            }
        }

        &.is-show {
            display: block;
        }
    }

    

    .btn-cat {
        a {
            width: 115px;
        }
    }

    /* ------------------------------------------------------------
    *
    common
    *
    ------------------------------------------------------------ */

    .t_wrap {
        margin-bottom: 2.5rem;
    }

    .title {
        padding-top: 1.3rem;
    }
    
    .th {
        padding: 1.9rem 0 2.5rem;

        .cast,
        .billing {
            text-align: left;
            font-size: .63rem;
            line-height: 1.6;
        }

        .about {
            padding-bottom: 1.5rem;
            text-align: left;

            p {
                padding-bottom: 2rem;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .btn {
            margin-bottom: 1.6rem;
        }
    }

    .th_theatre {
        padding-bottom: 1rem;
        // font-size: 1.3rem;
    }

    .news_list {
        margin-bottom: 0;

        li {
            flex-wrap: wrap;

            p {
                margin-bottom: .6rem;
            }

            .tit {
                display: block;
                margin-bottom: 2rem;
                width: 100%;
            }
        } 
    }


    /* ------------------------------------------------------------
    *
    footer
    *
    ------------------------------------------------------------ */

    #footer {
        background: #181E4D url(/elements/img/ft_bg_sp.jpg) no-repeat;
        background-size: 100% auto;

        #ft_sns {
            padding: 1.3rem 0;
        }

        .ft_logo {
			margin: 1.9rem 0 2.1rem;
            padding: 0 1rem;

            img {
                width: 14.25rem;
            }
        }

        .th {
            padding: 0 1rem 2.2rem;

            ul {
                padding-bottom: .7rem;

                .support {
                    font-size: .6rem;
                    padding-bottom: .8rem;
                    text-align: left;
                    line-height: 1.6;
                }

                .staff {
                    margin-bottom: 1.5rem;
                    text-align: left;
                    line-height: 1.6;
                }
            }

            // .theatre {
            //     font-size: 1.3rem;
            // }
        }

        .scap {
            text-align: left;
        }

        #copyright {
            padding-bottom: 1.7em;
        }
    }
}

@media (max-height: 414px) {
    #header {
        #gnav {
            overflow-y: auto;

            ul {
                height: 110vh;
            }

            li {
                &:last-child {
                    padding-bottom: 6.3rem;
                }
            }
        }
    }
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000; opacity: 0.8; filter: alpha(opacity = 80);}
#colorbox{outline:0;}

    #cboxTopLeft{width:14px; height:14px;/* background:url(images/controls.png) no-repeat 0 0;*/}
    #cboxTopCenter{height:14px;/* background:url(images/border.png) repeat-x top left;*/}
    #cboxTopRight{width:14px; height:14px;/* background:url(images/controls.png) no-repeat -36px 0;*/}
    #cboxBottomLeft{width:14px; height:43px;/* background:url(images/controls.png) no-repeat 0 -32px;*/}
    #cboxBottomCenter{height:43px;/* background:url(images/border.png) repeat-x bottom left;*/}
    #cboxBottomRight{width:14px; height:43px;/* background:url(images/controls.png) no-repeat -36px -32px;*/}
    #cboxMiddleLeft{width:14px;/* background:url(images/controls.png) repeat-y -175px 0;*/}
    #cboxMiddleRight{width:14px;/* background:url(images/controls.png) repeat-y -211px 0;*/}
    #cboxContent{background:#fff; overflow:visible;}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxLoadedContent{margin-bottom:5px;}
/*        #cboxLoadingOverlay{background:url(images/loading_background.png) no-repeat center center;}
        #cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}*/
        #cboxTitle{position:absolute; bottom:-25px; left:0; text-align:center; width:100%; font-weight:bold; color:#7C7C7C;}
        #cboxCurrent{position:absolute; bottom:-25px; left:58px; font-weight:bold; color:#7C7C7C;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
/*        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible;  position:absolute; bottom:-29px; background:url(images/controls.png) no-repeat 0px 0px; width:23px; height:23px; text-indent:-9999px;}*/
        
        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxPrevious{left:0px; background-position: -51px -25px;}
        #cboxPrevious:hover{background-position:-51px 0px;}
        #cboxNext{left:27px; background-position:-75px -25px;}
        #cboxNext:hover{background-position:-75px 0px;}
        #cboxClose{right:0; background-position:-100px -25px;}
        #cboxClose:hover{background-position:-100px 0px;}

        .cboxSlideshow_on #cboxSlideshow{background-position:-125px 0px; right:27px;}
        .cboxSlideshow_on #cboxSlideshow:hover{background-position:-150px 0px;}
        .cboxSlideshow_off #cboxSlideshow{background-position:-150px -25px; right:27px;}
        .cboxSlideshow_off #cboxSlideshow:hover{background-position:-125px 0px;}


/* ----------------------------------------------------------------------
  Custom
---------------------------------------------------------------------- */

#cboxTitle,
#cboxCurrent,
#cboxPrevious,
#cboxNext,
#cboxSlideshow {
    display: none !important;
}

#cboxContent {
    background: none;
    border-radius: 10px;
    margin-bottom: 0px;
}

#cboxContent #cboxClose {
	position: absolute;
	top: 14px;
	right: 16px;
	width: 32px;
    height: 42px;
    border-radius: 40px;
	background-color: #999;
    border: 0;
	z-index: 10;
    text-indent: -9999px;
}
#cboxContent #cboxClose:before {
	content: "\f00d";
	display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    text-rendering: auto;
    text-align: center;
    text-indent: initial;
    color: #fff;
    font-size: 1.88rem;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@media only screen and (max-width: 767px) {
    #cboxOverlay {
        background: #fff;
        opacity: 1;
    }
    #cboxContent #cboxClose {
        top: 4px;
        right: 0;
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
    }

    #cboxContent #cboxClose::before {
        font-size: 1.6rem;
    }

    #cboxContent .pp_content {
        overflow-y: scroll;
    } 
}


/* === Homemade Apple - regular */
@font-face {
	font-family: 'Homemade Apple';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//HomemadeApple-Regular.eot");
	src: local('Homemade Apple'),
		url("./../css/font//HomemadeApple-Regular.eot") format("embedded-opentype"),
		url("./../css/font//HomemadeApple-Regular.woff2") format("woff2"),
		url("./../css/font//HomemadeApple-Regular.woff") format("woff"),
		url("./../css/font//HomemadeApple-Regular.ttf") format("truetype"),
		url("./../css/font//HomemadeApple-Regular.svg") format("svg");
}

/* === Mansalva - regular */
@font-face {
	font-family: 'Mansalva';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//Mansalva-Regular.eot");
	src: local('Mansalva'),
		url("./../css/font//Mansalva-Regular.eot") format("embedded-opentype"),
		url("./../css/font//Mansalva-Regular.woff2") format("woff2"),
		url("./../css/font//Mansalva-Regular.woff") format("woff"),
		url("./../css/font//Mansalva-Regular.ttf") format("truetype"),
		url("./../css/font//Mansalva-Regular.svg") format("svg");
}

/* === Noto Sans JP - regular */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Regular.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Regular.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Regular.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Regular.woff") format("woff"),
		url("./../css/font//NotoSansJp-Regular.svg") format("svg");
}

/* === Noto Sans JP - 500 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Medium.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Medium.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Medium.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Medium.woff") format("woff"),
		url("./../css/font//NotoSansJp-Medium.svg") format("svg");
}

/* === Noto Sans JP - 700 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Bold.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Bold.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Bold.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Bold.woff") format("woff"),
		url("./../css/font//NotoSansJp-Bold.svg") format("svg");
}

/* === Noto Serif JP - regular */
@font-face {
	font-family: 'Noto Serif JP';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//NotoSerifJp-Regular.eot");
	src: local('Noto Serif JP'),
		url("./../css/font//NotoSerifJp-Regular.eot") format("embedded-opentype"),
		url("./../css/font//NotoSerifJp-Regular.woff2") format("woff2"),
		url("./../css/font//NotoSerifJp-Regular.woff") format("woff"),
		url("./../css/font//NotoSerifJp-Regular.svg") format("svg");
}

/* === Noto Serif JP - 500 */
@font-face {
	font-family: 'Noto Serif JP';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./../css/font//NotoSerifJp-Medium.eot");
	src: local('Noto Serif JP'),
		url("./../css/font//NotoSerifJp-Medium.eot") format("embedded-opentype"),
		url("./../css/font//NotoSerifJp-Medium.woff2") format("woff2"),
		url("./../css/font//NotoSerifJp-Medium.woff") format("woff"),
		url("./../css/font//NotoSerifJp-Medium.svg") format("svg");
}

/* === Noto Serif JP - 700 */
@font-face {
	font-family: 'Noto Serif JP';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../css/font//NotoSerifJp-Bold.eot");
	src: local('Noto Serif JP'),
		url("./../css/font//NotoSerifJp-Bold.eot") format("embedded-opentype"),
		url("./../css/font//NotoSerifJp-Bold.woff2") format("woff2"),
		url("./../css/font//NotoSerifJp-Bold.woff") format("woff"),
		url("./../css/font//NotoSerifJp-Bold.svg") format("svg");
}
